<template>
  <div class="home-page">

    <ReadingProgressBar>

      <LazyHydrate when-visible>
        <LetsChat></LetsChat>
      </LazyHydrate>

      <transition name="showNotification">
        <LazyHydrate when-visible>
          <Notification :message="getNotification.value" v-if="displayNotification"/>
        </LazyHydrate>
      </transition>

      <HomePage></HomePage>

      <LazyHydrate when-visible>
        <div>
          <Expertise/>
        </div>
      </LazyHydrate>

      <LazyHydrate when-visible>
        <Partners></Partners>
      </LazyHydrate>

      <LazyHydrate when-visible>
        <Contact></Contact>
      </LazyHydrate>

      <LazyHydrate when-visible>
        <Footer></Footer>
      </LazyHydrate>

    </ReadingProgressBar>
  </div>

</template>

<script>

import HomePage from '../components/Home/HomePage.vue';
// import Footer from '../components/Home/Footer.vue';
import ReadingProgressBar from "../components/Home/ReadingProgressBar";
// import Notification from "@/components/Notification.vue"
import {mapGetters, mapActions} from "vuex";
// import LetsChat from "../components/LetsChat";
import LazyHydrate from "vue-lazy-hydration"

export default {
  name: 'Home',

  data() {
    return {
      displayNotification: false,
    }
  },

  components: {
    LazyHydrate,
    ReadingProgressBar,
    HomePage,
    LetsChat: () => import('@/components/LetsChat.vue'),
    Expertise: () => import('@/components/Home/Expertise.vue'),
    Partners: () => import('@/components/Home/Partners.vue'),
    Contact: () => import('@/components/Home/Contact.vue'),
    Footer: () => import('@/components/Home/Footer.vue'),
    Notification: () => import('@/components/Notification.vue'),
  },

  computed: mapGetters(['getNotification']),

  created() {
    if (!this.getNotification.hasBeenDisplayed) {
      window.addEventListener('scroll', this.scrollHandler);
    }
  },
  mounted() {
    document.onload = () => {
      this.isMounted = true
    }
  },
  methods: {

    ...mapActions(['fetchNotification']),

    scrollHandler: function () {

      this.fetchNotification();
      this.displayNotification = this.getNotification.hasBeenDisplayed;
      window.removeEventListener('scroll', this.scrollHandler);
      setTimeout(() => {
        this.displayNotification = false;
      }, 5000);

    }

  }

}
</script>

<style lang="scss" scoped>
.home-page .contact-us {
  background: #fff;
}

.showNotification-enter, .showNotification-leave-to {
  opacity: 0;
  transform: translate(-50%, -50px);
}

.showNotification-enter-active, .showNotification-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}

.showNotification-enter-to, .showNotification-leave {
  opacity: 1;
  transform: translate(-50%, 0px);
}

@media only screen and (max-width: 425px) {
  .showNotification-enter, .showNotification-leave-to {
    opacity: 0;
    transform: translate(-50%, calc(100% + 50px));
  }

  .showNotification-enter-active, .showNotification-leave-active {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease;
  }

  .showNotification-enter-to, .showNotification-leave {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
}

</style>
